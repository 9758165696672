import Alpine from 'alpinejs'

//Alpine.directive('foo', ...)

//document.addEventListener('alpine:init', () => {
    Alpine.data('main', () => ({
        scrolled: false,
        mopen: false,

        onScroll() {
            this.scrolled = (window.pageYOffset > 20);
        },

        getNavBarClass() {
            return (this.scrolled || this.mopen) ? 'bg-white bg-opacity-95 shadow' : '';
        },

        toggleMenu() {
            this.mopen = !this.mopen;
        },

        getMenuButtonClass() {
            return this.scrolled ? 'mt-0' : '-mt-2';
        },

        contact() {
            return atob('bWFpbHRvOmF1cmVsaWVAY29kZXV6LmNvbQ==');
        }
    }));
//});

window.Alpine = Alpine
window.Alpine.start()